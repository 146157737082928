@font-face {
    font-family: 'Lexend Deca';
    font-style: normal;
    font-weight: 400;
    src: local('Lexend Deca Regular'), local('LexendDeca-Regular'), url(https://fonts.gstatic.com/s/lexenddeca/v1/K2F1fZFYk-dHSE0UPPuwQ5qnJy_YZ2ON.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
    height: 100%;
}
body {
    font-family: 'Lexend Deca', sans-serif;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top: solid 6px #6a6ae1;
}
#logo {
    display: flex;
    flex-direction: row;
    text-decoration: none;;
}
header img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
header nav {
}
header nav li {
    display: inline-block;
    list-style-type: none;
}
header nav a {
    text-decoration: none;
    border-bottom: solid 2px currentColor;
}
h1, h2, h3, h4 {
    color: #6a6ae1;
}
h1 {
    font-size: 35px;
}
p {
    margin: 10px 0;
    font-weight: 100;
    color: #333;
}
a {
    color: #6a6ae1;
}
.page {
    display: flex;
    justify-content: center;
    align-items: center;
}
.button {
    display: inline-block;
    padding: 1em;
    background-color: #333;
    text-decoration: none;
    color: white;
    transition: color 0.5s ease;

    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #6a6ae1;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.button:hover, .button:focus, .button:active {
    color: white;
}
.button:hover:before, .button:focus:before, .button:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.splash {
    display: flex;
    flex-direction: row;
    width: 100vw;
    min-height: 80vh;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: left;
}
.hero {
    height: 30vw;
    width: 30vw;
    margin-right: 50px;
    animation: herospin 5s infinite ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
}

.hero .background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(270deg, #6a90e1, #6a6ae1, #e16a92);
    animation: rotateGradient 3s linear infinite;
    z-index: 0;
}

.hero img {
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@keyframes herospin {
    0% { transform: rotateY(-40deg); }
    100% { transform: rotateY(40deg); }
}

@keyframes rotateGradient {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.column {
    max-width: 50vw;
}

@media (max-width: 700px) {
    .hero {
        display: none;
    }
    .column {
        max-width: none;
        padding: 25px;
    }
}


#map-container {
  width: 100%;
  height: 90vh;
}

footer {
    text-align: center;
}